<template>
  <div class="container">
    <loading :active.sync="uploaded"></loading>
    <div class="large-12 medium-12 small-12 cell">
      <v-form ref="formupload" :lazy-validation="lazy">
        <v-card max-width="800" class="pa-10">
          <h3>อัพโหลดไฟล์</h3>
          <v-row class="mt-10">
            <v-col>
              <v-select
                v-model="type"
                :items="items"
                item-text="text"
                item-value="value"
                label="เลือก ภ.ด.ส"
                :rules="Rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <input
                type="file"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
                :rules="Rules.required"
              />
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-col>
              <v-btn
                color="#744D9c"
                style="color: white"
                v-on:click="submitFile()"
                >อัพโหลด</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      lazy: false,
      uploaded: false,
      file: "",
      type: "",
      items: [
        {
          text: "ภ.ด.ส.1",
          value: "pds1",
        },
        {
          text: "ภ.ด.ส.3",
          value: "pds3",
        },
        {
          text: "ภ.ด.ส.7",
          value: "pds7",
        },
      ],
      Rules: {
        required: [(v) => !!v || "กรุณาเลือกข้อมูล"],
      },
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    // this.getfile();
  },

  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("file", file);
      console.log(this.type);
    },

    async submitFile() {
      if (this.$refs.formupload.validate(true)) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("type", this.type);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        this.uploaded = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API}/pds1s/formData`,
          formData,
          auth
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response.data);
        this.uploaded = false;
        if (response.data.response_status == "SUCCESS") {
          await this.$swal
            .fire({
              text: "อัพโหลดข้อมูลสำเร็จแล้ว กดเพื่อดูข้อมูล",
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ดูข้อมูล",
              cancelButtonText: "ยกเลิก",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push(this.type);
              }
            });
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
        // })
        // .catch(function () {
        //   console.log("FAILURE!!");
        // });
      }
    },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
